import { Component, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import {
  IViewField,
  IViewModeUserConfigurationColumn,
  ViewFieldTypeEnum,
  ViewModeColumnBasedFieldSort,
  ViewModeTableColumn,
  ViewsFieldVbo,
  ViewsFieldVboSelectionMode,
  ViewSortDirection,
  ViewsVboUserConfiguration
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ListComponent2Service } from '../../../list.service';
import { Guid } from '../../../../../../../node_modules/guid-typescript/dist/guid';
import { ViewModeUtils } from '../../viewmode.utils';
import { getInSafe, isNullOrUndefined } from '../../../../utils/typescript.utils';
import { VboOperations } from '../../../events/vboitemtoogle.eventdata';
import { ToastrService } from 'ngx-toastr';

/**
 * This is the component used to deal with row headers.
 */
@Component({
  // Include this component as and attribute selector component to simplofy the
  // css treatment.
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'th[app-view-grid-header], div[app-view-grid-header]',
  templateUrl: './gridheader.component.html'
})
export class ViewGridHeaderComponent implements OnInit, OnDestroy, OnChanges {

  /**
   * The field include info
   */
  @Input() headerField: IViewModeUserConfigurationColumn;

  /**
   * Header text
   */
  header: ViewModeTableColumn;

  /**
   * Types enum.
   */
  types = ViewFieldTypeEnum;

  /**
   * If the VBO is checked for this cell.
   * @type {boolean}
   */
  vboChecked: boolean;

  /**
   * vbo service toggle subscription
   */
  vboItemToggleSubscription;

  /**
   * Id unico del componente
   */
  uniqueComponentId: string;

  /**
   * Class constructor
   * @param {ListComponent2Service} viewService View orchestrator.
   */
  constructor(public viewService: ListComponent2Service,
              protected toastService: ToastrService) {
    // @ts-ignore
    this.uniqueComponentId = Guid.create();
  }

  /**
   * Subscribes to vboItemToggleHandler
   */
  ngOnInit(): void {
    this.vboItemToggleSubscription = this.viewService
      .onVboItemToggle
      .subscribe(event => {
        switch (event.operation) {
          case VboOperations.SELECT_ALL:
            this.vboChecked = true;
            break;
          case VboOperations.UNSELECT_ALL:
            this.vboChecked = false;
            break;
        }
      });
  }

  /**
   * Unsubscribe event emitter when component destroyed
   */
  ngOnDestroy(): void {
    this.vboItemToggleSubscription.unsubscribe();
  }

  /**
   * notify when child component change
   *
   * @memberof GridComponent
   */
  ngOnChanges(): void {
    this.header = this.getViewModeTableColumn(this.headerField);
  }

  /**
   * Gets the tooltip
   */
  get tooltip(): string {
    const headerColumn: any = this.getField(this.headerField);
    if (isNullOrUndefined(headerColumn)) {
      return null;
    }
    return headerColumn.ToolTip;
  }

  /**
   * Returns a ViewModeTableColumn with the info for the current column.
   *
   * @returns {ViewModeTableColumn}
   */
  getViewModeTableColumn(headerUserField: IViewModeUserConfigurationColumn): ViewModeTableColumn {
    return ViewModeUtils.getViewModeTableColumn(headerUserField, this.viewService);
  }

  /**
   * Returns the field for the current column
   */
  getField(headerUserField: IViewModeUserConfigurationColumn): IViewField {
    return ViewModeUtils.getViewFieldFromColumnHeaderUserField(headerUserField, this.viewService);
  }

  /**
   * Returns the type of the current field.
   */
  getType(): ViewFieldTypeEnum | false {
    const item: IViewField = this.getField(this.headerField);
    return item ? item.Type : false;
  }

  /**
   * When the header is clicked
   */
  headerClicked(): void {
    if (!this.isSortable()) {
      return;
    }
    const sort: ViewModeColumnBasedFieldSort = new ViewModeColumnBasedFieldSort();
    sort.Direction = this.viewService.nextDirection(this.viewService.getCurrentSort(this.headerField.Field));
    sort.Field = this.headerField.Field;
    this.viewService.setSort(sort);
  }

  /**
   * Check if the order is ascending
   * @param {number} it index of the header
   * @returns if the sort is ascendentig
   * @memberof GridComponent
   */
  isAscendantSort(): boolean {
    return this.viewService.getCurrentSort(this.headerField.Field) === ViewSortDirection.Ascending;
  }

  /**
   * Check if the order is descending
   *
   * @param {number} it index of the header
   * @returns if the sort is descendentig
   * @memberof GridComponent
   */
  isDescendantSort(): boolean {
    return this.viewService.getCurrentSort(this.headerField.Field) === ViewSortDirection.Descending;
  }

  /**
   * If this field can be sorted
   */
  isSortable(): boolean {
    if (!this.header) {
      return false;
    }
    return this.header.Sortable === true;
  }

  @HostListener('click')
  onClick(): void {
    this.headerClicked();
  }

  /**
   * Title attribute
   */
  @HostBinding('title')
  get getTitle(): string {
    return this.tooltip ?? '';
  }

  /**
   * Propagate classes to the host element
   */
  @HostBinding('class')
  get getClasses(): string {
    const classes: string[] = [];
    const getClassResult: { [cl: string]: boolean } = this.getClass();
    for (const c of Object.keys(getClassResult)) {
      if (getClassResult[c] === true) {
        classes.push(c);
      }
    }
    classes.push.apply(classes, ViewModeUtils.resolveTableHeaderCellClasses(this.headerField, this.viewService));
    return classes.join(' ');
  }

  /**
   * Returns an object with custom header classes.
   *
   * @returns {object}
   */
  getClass(): { [cl: string]: boolean } {
    return {
      'no-order': (!this.isDescendantSort() && !this.isAscendantSort() && this.isSortable()),
      'order-desc': this.isDescendantSort(),
      'order-asc': this.isAscendantSort(),
      'sortable': this.isSortable(),
      'has-tooltip': !isNullOrUndefined(this.tooltip),
      'view-table-head-tr-th': true,
      'app-view-grid-header-cell': true
    }
  }

  getSortClass(): { [cl: string]: boolean } {
    return {
      'no-order': (!this.isDescendantSort() && !this.isAscendantSort() && this.isSortable()),
      'order-desc': this.isDescendantSort(),
      'order-asc': this.isAscendantSort()
    }
  }

  /**
   * Check if VBO Checked
   */
  vboCheckHandler(): void {
    this.vboChecked = !this.vboChecked;
    this.viewService.vboToggleAll();
  }

  /**
   * Returns a boolean indicating if the "select all items" VBO option is available on the view.
   *
   * @returns {boolean}
   */
  isVboSelectAllAvailable(): boolean {
    const field: ViewsFieldVbo = this.getField(this.headerField) as ViewsFieldVbo;
    const selectionModeIsMultiple: boolean = this.viewService.vboItemSelectionMode(this.headerField.Field) === ViewsFieldVboSelectionMode.Multiple;
    const resultsAvailable: boolean = this.viewService.resultsAvailableForCurrentUserConfiguration();
    const allItemsInAllPagesSelected: boolean = getInSafe(this.viewService.getUserConfiguration(), v => (v.UserConfigurations.vbo as ViewsVboUserConfiguration).AllItemsInAllPages, false);
    return field.EnableSelectAllItemsFromAllPages === true && selectionModeIsMultiple && (resultsAvailable || allItemsInAllPagesSelected);
  }
}
