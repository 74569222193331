import { ChangeDetectorRef, ComponentRef, Directive, EventEmitter, Injectable, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { delay, map, take, takeUntil } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import {
  ActionsApiAction,
  CoreHashedKey,
  DtoFrontendModal,
  DtoFrontendModalSize,
  DtoFrontendModalType,
  FormConfiguration,
  FormElement,
  FormElementAutocomplete,
  FormElementAutocompleteCallbackResult, FormElementAutocompleteNew,
  FormElementAutocompleteNewCallbackResult,
  FormElementAutocompleteRequest,
  FormElementAvatar,
  FormElementButton,
  FormElementCheckboxesModal,
  FormElementDate,
  FormElementDatePicker,
  FormElementDateTimePicker,
  FormElementFile,
  FormElementFixedOptionsBase,
  FormElementFormatItemsRequest,
  FormElementFormatItemsRequestResult,
  FormElementFromContentsProgress,
  FormElementHtmlPlaceholder,
  FormElementInput,
  FormElementInputAsyncValueCallbackRequest,
  FormElementInputAsyncValueCallbackResult,
  FormElementInputNumber,
  FormElementListSelectorResult,
  FormElementMap,
  FormElementQRCode,
  FormElementRadio,
  FormElementRunCustomCallbackApiTriggerData,
  FormElementRunCustomCallbackHandlerRequest,
  FormElementSecret,
  FormElementSelectDynamicRequest,
  FormElementSimpleMessage,
  FormElementType,
  FormElementVideo,
  FormElementViewsEmbed,
  FormPluginRequest,
  FormState,
  FormSubmitData,
  FormSubmitType,
  FormValidationError,
  Payload,
  WebServiceResponseTyped
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DecoupledModalBridgeService } from '../../decoupled-modal/decoupled-modal-bridge.service';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import {
  asIterable,
  backendTypeMatch,
  getInSafe,
  isNullOrUndefined,
  JsonPathEvaluate,
  JsonPathSanitizer,
  JsonPathSet,
  UtilsTypescript
} from '../../utils/typescript.utils';
import { ButtonClickedEventData } from '../form-components/button/buttonclicked.eventdata';
import { FrontendFormElementInput } from '../form-components/formelementinput.class';
import { FrontendFormElementWrapper } from '../form-components/formelementwrapper.class';
import { FieldConfig } from '../interfaces/field-config.interface';
import { IFrontendFormElement } from '../interfaces/field.interface';
import { IFormEvent } from '../interfaces/formevent.interface';
import { StateApiService } from '../state-api/state-api.service';
import { ValuechangedEventdata } from '../valuechanged.eventdata';
import { PreserveFrontendValueService } from '../preserve-frontend-value.service';
import { ClientCache } from '../../../core/clientcache/ClientCacheClass';
import { stateApiTriggerSourceData } from '../state-api/stateApiTriggerSourceData';
import { FormService } from '../../../core/services/ETG_SABENTISpro_Application_Core_form.service';
import { Formstatecontainer } from '../formstatecontainer.class';
import { HttpHeaders } from '@angular/common/http';
import { ConfirmDialogLabels } from '../../decoupled-modal/models/modal-params.interface';

/***
 * TODO: Toda la gestión del formulario debería hacerse desde este servicio!!
 */
@Directive()
@Injectable()
export class FormManagerService extends DestroyableObjectTrait {

  /**
   * The current form state
   */
  private formState: Formstatecontainer;

  /**
   * Eventos genéricos de salida del formulario
   */
  @Output() formEvent: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Eventos genéricos de salida del formulario
   */
  @Output() formEventComplex: EventEmitter<IFormEvent> = new EventEmitter<IFormEvent>();

  /**
   * El componente propietario (form-manager-component) se cuelga de este evento
   * para hacer los rebuilds.
   */
  @Output() submit: EventEmitter<{ emitter: string, submitType: FormSubmitType }> = new EventEmitter<{
    emitter: string,
    submitType: FormSubmitType
  }>();

  /**
   * Los botones de formulario llaman directamente a este evento
   */
  @Output() buttonClicked: EventEmitter<ButtonClickedEventData> = new EventEmitter<ButtonClickedEventData>();

  /**
   * El string del argument es el ClientPath del elemento. Permite avisar a los wrappers de los elementos
   * de que deben refrescarse
   */
  @Output() elementConfigChanged: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Se lanza cuando se producen cambios en el formState
   */
  @Output() formStateChanged: EventEmitter<Formstatecontainer> = new EventEmitter<Formstatecontainer>();

  /**
   * Scroll to top
   */
  @Output() scrollToTop: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Any value changed
   */
  @Output() changedValue: EventEmitter<ValuechangedEventdata> = new EventEmitter<ValuechangedEventdata>();

  /**
   * Indica si el formulario se ha construido por completo
   */
  @Output() materializationComplete: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   *
   */
  submitCount: number = 0;

  /**
   * Permite saber cuantas veces se ha recalculado la API de estados
   */
  stateApiCalculateCount: number = 0;

  /**
   * The state API manager
   */
  formStateApiManager: StateApiService;

  /**
   * Funcionalidad de preservar valores de campo en frontend
   */
  preserveValueManager: PreserveFrontendValueService;

  /**
   * Si hemos intentado enviar el formulario, pero hay errores de validación, guardamos aquí una
   * copia para mostrar.
   */
  submitAttemptWithClientValidationErrors: BehaviorSubject<FormValidationError[]> = new BehaviorSubject<FormValidationError[]>([]);

  /**
   * Tenemos lógica en los componentes de frontend (como si está o no vacío, etc..) y para
   * eso necesitamos la referencia a los propios componentes.
   */
  instanceComponents: { [key: string]: ComponentRef<IFrontendFormElement> } = {};

  /**
   * Usamos esto para evitar condiciones de carrera en las que
   * se envian requests de manera solapada (i.e. usuario presiona
   * multiples veces un boton de guardar mientras llega el feedback
   * de backend).
   */
  submittingRequest = false;

  /**
   * Las configuraciones de componente, esto es un espejo que lo que está en FormState.FORM
   * pero se mantiene por motivos LEGACY ya que los componentes se terminan BINDEANDO al final
   * contra estos objetos!
   */
  private config: FieldConfig;

  /**
   * Guardamos una copia del objeto de formulario de angular, para acceder
   * directamente a los controles.
   */
  form: FormGroup;

  /**
   * Change detector reference for the form
   */
  formChangeDetector: ChangeDetectorRef;

  /**
   * Por compatibilidad con los viejos selectores, hacemos un mapeo
   * de los nombres no-anidados de sus selectores a los valores con selectores
   * completos
   * Por ejemplo en el formulario:
   * FieldSet1
   * -- field1
   * FieldSet2
   * -- field1
   * Tendremos:
   * [field1] -> [FieldSet1.field1, FieldSet2.field2]
   */
  formClientIdToClientPaths: { [key: string]: string[] };

  /**
   * Este evento se lanza al re-cargar el formulario, requerido para cancelar
   * cualquier operación que existiera con la instancia anterior del formulario.
   */
  formOnInit: EventEmitter<void> = new EventEmitter<void>();

  /**
   * This flag indicates if the we're waiting for the form group status changes
   * before trying submitting the form on `implicitSubmit`.
   *
   * @see https://angular.io/api/forms/AbstractControl#statusChanges
   * @see https://angular.io/api/forms/AbstractControl#status
   */
  private waitingFormGroupStatusChanges: boolean = false;

  /***
   * Get an instance of
   *
   * @param formService
   */
  constructor(
      protected formService: FormService,
      protected fb: FormBuilder,
      protected dmbs: DecoupledModalBridgeService,
      protected clientCache: ClientCache
  ) {
    super();
    this.clearForm();
  }

  /**
   * Reattach all components and do change detection, this ensure that changed detection
   * runs on the full form (i.e. for rebuild values and other complex behaviours)
   */
  detectChangesAllForm(executeScrollToTop: boolean = true): void {
    for (const key of Object.keys(this.instanceComponents)) {
      const component: FrontendFormElementWrapper = this.instanceComponents[key].instance as FrontendFormElementWrapper;
      (component as FrontendFormElementWrapper).reattachChangeDetector();
    }
    this.formChangeDetector.detectChanges();
    for (const key of Object.keys(this.instanceComponents)) {
      const component: FrontendFormElementWrapper = this.instanceComponents[key].instance as FrontendFormElementWrapper;
      (component as FrontendFormElementWrapper).detachChangeDetector();
    }

    if (executeScrollToTop) {
      this.scrollToTop.emit();
    }
  }

  /**
   * Devuelve los valores del formulario en el formato
   * nativo de la API de angular (anidados)
   */
  getFormRawValuesNative(): object {
    return this
        .getForm()
        .getRawValue();
  }

  /**
   * Devuelve los valores del formulario aplanados en
   * un array
   */
  getFormRawValuesPlain(): object {
    const rawValues: object = this.getFormRawValuesNative();
    const plainValues: object = {};
    for (const componentKey of Object.keys(this.instanceComponents)) {
      if (this.instanceComponents.hasOwnProperty(componentKey)) {
        const config: FieldConfig = this.instanceComponents[componentKey].instance.config;
        // En la versión plana de valores los contenedores no están
        if (this.formElementIsContainer(config.FormElement.Type)) {
          continue;
        }
        const clientPathParts: string[] = config.ClientPath.split('.');
        let clientPathResult: string = '$';
        for (const part of clientPathParts) {
          clientPathResult += '["' + part + '"]';
        }
        const queryResult: string[] = JsonPathEvaluate(clientPathResult, rawValues);
        if (queryResult && queryResult.length) {
          plainValues[config.ClientId] = queryResult[0];
        }
      }
    }
    return plainValues;
  }

  /**
   * Obtiene los valores de formulario, anidados o plain según la configuración
   * que tenga el formulario en EnableElementValueNesting
   */
  getFormRawValues(): object {
    if ((this.config.FormElement as FormConfiguration).EnableElementValueNesting === true) {
      return this.getFormRawValuesNative();
    } else {
      return this.getFormRawValuesPlain();
    }
  }

  /**
   * Refresh a form element wrapper
   *
   * @param path
   */
  detectChangesFormElement(selector: string): void {
    selector = this.normalizeSelector(selector);
    (this.instanceComponents[selector].instance as any as FrontendFormElementWrapper).forceDetectChanges();
  }

  /**
   * Detach change detectors for all form element wrappers
   */
  detachWrapperChangeDetectors(): void {
    for (const key of Object.keys(this.instanceComponents)) {
      const component: FrontendFormElementWrapper = this.instanceComponents[key].instance as FrontendFormElementWrapper;
      (component as FrontendFormElementWrapper).detachChangeDetector();
    }
  }

  /**
   * Reset any current data...
   */
  clearForm(): void {
    this.formState = null;
    this.config = null;
    this.formClientIdToClientPaths = null;
    this.submittingRequest = false;
    this.submitAttemptWithClientValidationErrors.next([]);
    if (!isNullOrUndefined(this.formStateApiManager)) {
      this.formStateApiManager.ngOnDestroy();
      delete this.formStateApiManager;
    }
    this.instanceComponents = {};

    // Esto realmente no es un servicio, pero usamos el injector
    // para que pueda traerse cosas vía el constructor.
    if (this.formStateApiManager) {
      this.formStateApiManager.componentDestroyed$.next(null);
    }

    if (this.preserveValueManager) {
      this.preserveValueManager.componentDestroyed$.next(null);
    }

    this.formStateApiManager = new StateApiService(this, this.dmbs);
    this.preserveValueManager = new PreserveFrontendValueService(this, this.clientCache);
  }

  /**
   * Lanza todas las validaciones de cliente, y si alguna falla envía
   * al usuario al comienzo del formulario con un mensaje genérico.
   */
  verifyAndUpdateLastSubmitClientSideValidations(): Promise<boolean> {
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      // Recolectamos todos los errores de validación :(
      this.submitAttemptWithClientValidationErrors.next(this.collectClientSideValidationErrors());
    } else {
      this.submitAttemptWithClientValidationErrors.next([]);
    }
    return Promise.resolve(this.form.valid);
  }

  /**
   * Revisar si un elemento está efectivamente visible en la GUI (podría haber heredado visibilidad)
   * @param config
   */
  protected elementIsVisible(config: FieldConfig): boolean {
    while (config) {
      if (config.visible === false) {
        return false;
      }
      config = config.ParentConfig;
    }
    return true;
  }

  /**
   * Recolecta todos los errores de validación de frontend
   *
   * @param clientPath
   */
  collectClientSideValidationErrors(clientPath: string = null): FormValidationError[] {
    const result: FormValidationError[] = [];
    for (const c of Object.keys(this.instanceComponents)) {
      const comp: ComponentRef<IFrontendFormElement> = this.instanceComponents[c] as ComponentRef<IFrontendFormElement>;
      const wrapper: FrontendFormElementWrapper = comp.instance as FrontendFormElementWrapper;
      if (this.formElementIsContainer(wrapper.config.FormElement.Type) || wrapper.config.FormElement.Type === FormElementType.Secret) {
        continue;
      }
      // No considerar validaciones en elementos no visibles
      // if (!this.elementIsVisible(wrapper.config)) {
      //  continue;
      // }
      const control: AbstractControl = this.getFormComponent(wrapper.config.ClientPath);
      // Los controles deshabilitados no participan del flujo de validación de cliente
      if (!control.enabled) {
        continue;
      }
      let input: FrontendFormElementInput;
      try {
        input = wrapper.formElementInstance();
      } catch {
        continue;
      }
      const messages: ValidationErrors = input.validate(control);
      for (const m of Object.keys(messages)) {
        const m2: FormValidationError = new FormValidationError();
        m2.Message = messages[m];
        m2.Element = wrapper.config.ClientPath;
        m2.ValidationKey = m;
        result.push(m2);
      }
    }
    return result;
  }

  /**
   * Hace un envío implicito del formulario hacia el DefaultAction
   * que se haya configurado a nivel de formulario. Si el control
   * vinculado a la acción NO está habilitado/disponible no hace nada.
   *
   * @see https://www.tjvantoll.com/2013/01/01/enter-should-submit-forms-stop-messing-with-that/
   *
   * @param {boolean} waitForStatePropagation This allows the method
   * to create a subscription to elementChangeEvaluated on the StateApiService
   * to wait until the state has been propagated on the rest of components
   * of the form.
   */
  implicitSubmit(waitForStatePropagation: boolean = false): void {

    if (this.form.valid !== true) {
      return;
    }

    const defaultAction: string = this.formState.formState.Form.DefaultAction;
    if (UtilsTypescript.isNullOrWhitespace(defaultAction)) {
      return;
    }

    const defaultActionControl: AbstractControl = this.getFormComponent(defaultAction);
    const defaultActionControlConfig: FieldConfig = this.getFieldConfigFromSelector(defaultAction);

    if (defaultActionControlConfig.visible === false) {
      return;
    }

    if (defaultActionControl.enabled === true) {
      this.submitForm(defaultAction, FormSubmitType.Normal);
      return;
    }

    /**
     * If the form group is not enabled, then probably es being angular-validated.
     * Then wait for the next status update and trigger a `submitForm`.
     *
     * @see https://angular.io/api/forms/AbstractControl#statusChanges
     * @see https://angular.io/api/forms/AbstractControl#status
     */
    if (!this.waitingFormGroupStatusChanges && waitForStatePropagation) {
      this.waitingFormGroupStatusChanges = true;

      this.formStateApiManager.elementChangeEvaluated$
          .pipe(
              takeUntil(this.componentDestroyed$.asObservable()),
              takeUntil(this.submit.asObservable()),
              take(1))
          .subscribe(({controlKey, triggerElementData}) => {
            this.implicitSubmit();
            this.waitingFormGroupStatusChanges = false;
          });
    }
  }

  /**
   * Registar a form component's instance
   * @param selector
   * @param instance
   */
  registerFormComponent(componentRef: ComponentRef<IFrontendFormElement>): void {
    if (!isNullOrUndefined(this.instanceComponents[componentRef.instance.config.ClientPath])) {
      // Este throw se ha quitado porque cuando estamos haciendo un RebuildValues, se hace detección de cambios
      // lo que en algunos casos reconstruye los componentes y hacen que lleguemos aquí.
      // throw new Error('Cannot register the same component twice: ' + componentRef.instance.config.ClientPath);
    }
    this.instanceComponents[componentRef.instance.config.ClientPath] = componentRef;
  }

  /**
   * Obtiene la instancia de GUI (FrontendFormElementWrapper) de un componente de formulario
   *
   * @param selector
   */
  getFormComponentInstance(selector: string): ComponentRef<IFrontendFormElement> {
    selector = this.normalizeSelector(selector);
    return this.instanceComponents[selector];
  }

  /**
   * Obtiene la instancia el ControlValueAccesor/FrontendFormElementInput del componente
   * indicado en el selector. Lanza excepción si el componente no lo soporta.
   * @param selector
   */
  getFormComponentInputInstance(selector: string): FrontendFormElementInput {
    const wrapper: FrontendFormElementWrapper = this.getFormComponentInstance(selector).instance as FrontendFormElementWrapper;
    return wrapper.formElementInstance();
  }

  /**
   * Como getFormComponentInputInstance pero devuelve nulo si el componente no soporta el acceso a la instancia
   * @param selector
   */
  getFormComponentInputInstanceSafe(selector: string): FrontendFormElementInput {
    try {
      return this.getFormComponentInputInstance(selector);
    } catch {
      return null;
    }
  }

  /**
   * Gets and stores a clean formSate(private) within the component and serves a copy to suscribers
   * @param {string} formId
   * @param {Object} args
   * @returns {any}
   */
  init(pluginRequest: FormPluginRequest): Observable<Formstatecontainer> {
    this.formOnInit.next();
    return this.formService.postConfiguration(pluginRequest, pluginRequest.FormId, {showSpinner: false})
        .pipe(
            map(
                (data: WebServiceResponseTyped<string>) => {
                  const container: Formstatecontainer = this.parseFormState(data.result);
                  this.setFormState(container);
                  return container;
                }),
            takeUntil(this.componentDestroyed$),
            takeUntil(this.formOnInit)
        );
  }

  /**
   * Guardamos el formstate y una copia
   *
   * @param formState
   */
  setFormState(formState: Formstatecontainer, propagate: boolean = true): void {
    this.formState = formState;
    if (propagate === true) {
      this.formStateChanged.emit(formState);
    }
  }

  /**
   * returns a copy of the original formState
   * @returns {FormState}
   */
  getFormState(): FormState {
    return this.formState?.formState;
  }

  /**
   * Connects with postSubmit formService function
   * @param {Object} formInput
   * @returns {Observable<Object>}
   */
  postSubmit(formInput: FormSubmitData, showSpinnerInmeditely: boolean = true): Observable<Formstatecontainer> {
    return this.formService
        .postSubmit(this.formState.signedFormState, formInput, this.formState.formState.FormId, {
          showSpinner: true,
          showSpinnerInmediately: showSpinnerInmeditely,
          headers: new HttpHeaders().set('meta-propagateexception', 'true')
        })
        .pipe(
            map((i: WebServiceResponseTyped<string>) => {
              const container: Formstatecontainer = this.parseFormState(i.result);
              return container;
            })
        );
  }

  /**
   * Regenera el FORMSTATE a partir de su versión firmada. La firma en cliente
   * nos da igual, es un tema exclusivo de servidor. Aquí no se comprueba
   * la firma solo se quita.
   * @param signedFormState
   */
  parseFormState(signedFormState: string): Formstatecontainer {
    const container: Formstatecontainer = new Formstatecontainer();
    container.formState = JSON.parse((jwt_decode(signedFormState) as Payload).data);
    container.signedFormState = signedFormState;
    return container;
  }

  /**
   * Conocer si el formulario está  ocupado
   */
  formIsBussy(): boolean {
    return Object.values(this.instanceComponents)
        .filter((i) => getInSafe(i.instance as FrontendFormElementWrapper,
            (j) => j.formElementInstance().componentIsBussy()))
        .length > 0;
  }

  /**
   * Connect with formService.postFieldautocompletecallback
   * brings autocomplete options
   * @param {Object} formInput -> when the input has a parent input, formInput references it
   * @param {string} fieldSelector -> input name/title
   * @param {string} search
   * @param currentPage
   * @returns {Observable<Object>}
   */
  getFieldautocompletecallback(formInput: FormSubmitData,
                               fieldSelector: string,
                               search: string,
                               currentPage: number = 0): Observable<FormElementAutocompleteCallbackResult> {
    const request: FormElementAutocompleteRequest = new FormElementAutocompleteRequest();
    request.Page = currentPage;
    request.Search = search;
    request.FieldSelector = fieldSelector;
    return this.formService
        .postFieldautocompletecallback(this.formState.signedFormState, formInput, request, this.formState.formState.FormId)
        .pipe(
            takeUntil(this.componentDestroyed$),
            map((i) => i.result)
        );
  }

  /**
   * Callback para valor asíncrono de campo
   * @param element
   * @param formInput
   */
  getElementInputAsyncValueCallback(element: FormElement, formInput: FormSubmitData): Observable<FormElementInputAsyncValueCallbackResult> {
    const request: FormElementInputAsyncValueCallbackRequest = new FormElementInputAsyncValueCallbackRequest();
    request.FieldSelector = element.ClientPath;

    return this.formService
        .postElementasyncvaluecallback(this.formState.signedFormState, formInput, request, this.formState.formState.FormId, {showSpinner: false})
        .pipe(
            // Le ponemos un random para evitar que todas las llamadas salgan a la vez
            delay(Math.random() * 1500),
            map((i) => i.result),
            takeUntil(this.componentDestroyed$)
        );
  }

  /**
   *
   * @param formInput
   * @param fieldSelector
   * @param search
   * @param currentPage
   * @param retrieveKeys
   */
  getFieldautocompletecallbackNew(formInput: FormSubmitData,
                                  fieldSelector: string,
                                  search: string,
                                  currentPage: number = 0,
                                  retrieveKeys: CoreHashedKey[] = null): Observable<FormElementAutocompleteNewCallbackResult> {
    const request: FormElementAutocompleteRequest = new FormElementAutocompleteRequest();
    request.Page = currentPage;
    request.Search = search;
    request.FieldSelector = fieldSelector;
    request.RetrieveKeys = retrieveKeys;
    return this.formService
        .postFieldautocompletecallbacknew(this.formState.signedFormState, formInput, request, this.formState.formState.FormId, {showSpinner: false})
        .pipe(
            takeUntil(this.componentDestroyed$),
            map((i) => i.result)
        );
  }

  /**
   *
   * @param formInput
   * @param fieldSelector
   * @param search
   * @param currentPage
   * @param retrieveKeys
   */
  getFieldSelectorDynamiccallback(formInput: FormSubmitData,
                                  fieldSelector: string,
                                  keys: CoreHashedKey[] = null): Observable<FormElementListSelectorResult> {
    const request: FormElementSelectDynamicRequest = new FormElementSelectDynamicRequest();
    request.FieldSelector = fieldSelector;
    request.RetrieveKeys = keys;
    return this.formService
        .postFieldselectdynamyccallbacknew(this.formState.signedFormState, formInput, request, this.formState.formState.FormId)
        .pipe(
            takeUntil(this.componentDestroyed$),
            map((i) => i.result)
        );
  }

  /**
   *
   * @param formInput
   * @param fieldSelector
   * @param search
   * @param currentPage
   * @param retrieveKeys
   */
  runCustomCallback(targetElement: string, action: ActionsApiAction, triggerElementData: stateApiTriggerSourceData): void {
    const request: FormElementRunCustomCallbackHandlerRequest = new FormElementRunCustomCallbackHandlerRequest();
    request.HandlerId = (action.Data as FormElementRunCustomCallbackApiTriggerData).HandlerId;
    const formSubmitData: FormSubmitData = new FormSubmitData();
    formSubmitData.formInput = this.getFormComponentValue('');

    // El getFormComponentValue obtiene el valor previo, "falseamos" esto para que llegue el nuevo valor
    JsonPathSet(JsonPathSanitizer(targetElement), formSubmitData.formInput, triggerElementData.newValue);

    formSubmitData.submitElement = targetElement;
    request.FieldSelector = targetElement;

    this.formService
        .postFormruncustomcallback(this.formState.signedFormState, formSubmitData, request, this.formState.formState.FormId, {showSpinner: true})
        .pipe(
            takeUntil(this.componentDestroyed$)
        ).subscribe(x => {
      if (!isNullOrUndefined(x.result?.OverridedValues)) {
        Object.keys(x.result.OverridedValues).forEach(key => {
          this.setFormComponentValue(key, x.result.OverridedValues[key], true);
        })
      }
    });
  }

  /**
   * Format items callback
   *
   * @param formInput
   * @param items
   * @param fieldSelector
   */
  callbackFormatItems(items: CoreHashedKey[], fieldSelector: string): Observable<FormElementFormatItemsRequestResult> {
    const request: FormElementFormatItemsRequest = new FormElementFormatItemsRequest();
    request.FieldSelector = fieldSelector;
    request.Keys = items;
    return this.formService.postFormformatitems(this.formState.signedFormState, this.form.getRawValue(), request, this.formState.formState.FormId, {showSpinner: false})
        .pipe(
            takeUntil(this.componentDestroyed$),
            map((response: WebServiceResponseTyped<FormElementFormatItemsRequestResult>) => {
              return response.result as FormElementFormatItemsRequestResult;
            })
        );
  }

  /**
   * Get the current first-level (parent) form
   */
  getForm(): FormGroup {
    return this.form;
  }

  /**
   * Force a form submit
   * @param formValues
   * @param emitter
   * @param submitType
   */
  submitForm(emitter: string, submitType: FormSubmitType): void {
    if (isNullOrUndefined(submitType)) {
      submitType = FormSubmitType.Normal;
    }

    if (this.formIsBussy()) {
      this.dmbs.showConfirm(
          {
            messages: ['Actualmente el formulario se encuentra ocupado y no puede realizar esta operación.'],
            NoLabel: null,
            YesLabel: 'Aceptar',
            notVisibleNo: true
          } as ConfirmDialogLabels,
          {
            Title: 'Formulario ocupado',
            HideHeader: false,
            CssClasses: [],
            ModalSize: DtoFrontendModalSize.Small,
            ModalType: DtoFrontendModalType.Modal
          } as DtoFrontendModal);
      return null;
    }
    // En realidad mandamos un evento ya que el rebuild lo debe hacer el formmanager
    this.submit.emit({emitter: emitter, submitType: submitType});
  }

  /**
   * Register form and initializes api-state
   * @param form
   */
  registerForm(form: FormGroup, changeDetector: ChangeDetectorRef): void {
    this.form = form;
    /*

    Para debugar cuando un formulario cambia de estado a dirty

    const markAsDirtyOriginalFunction: any = this.form.markAsDirty;
    this.form.markAsDirty = function (): void {
      markAsDirtyOriginalFunction.apply(this, arguments);
      console.log('Marked as dirty!');
    }
    */
    this.formChangeDetector = changeDetector;
    this.instanceComponents = {};
  }

  /**
   * Form elements that support nested children
   *
   * @param formElementType
   */
  formElementIsContainer(formElementType: FormElementType): boolean {
    return this.containerTypes.includes(formElementType);
  }

  /**
   * Tipos de componente que pueden actuar como contenedor
   */
  get containerTypes(): FormElementType[] {
    return [FormElementType.Table, FormElementType.FieldSet, FormElementType.Form, FormElementType.Compound];
  }

  /**
   * Materialize a form group, used in both form and fieldset
   *
   * @param formElement
   * @param formGroup
   */
  materializeControlGroup(formElement: FormElement,
                          formGroup: FormGroup,
                          rows: { [key: number]: FieldConfig[] }): FormGroup {

    if (!this.formElementIsContainer(formElement.Type)) {
      throw new Error('Control group materialization for form element not supported: ' + formElement.ClientPath);
    }

    let currentConfig: FieldConfig;

    // El form como es el de primer nivel es un contenedor con un tratmiento especial
    if (formElement.Type === FormElementType.Form) {
      if (isNullOrUndefined(this.config)) {
        this.config = {};
        currentConfig = this.config;
        currentConfig.children = {};
        currentConfig.FormElement = formElement;
        this.formClientIdToClientPaths = {};
      } else {
        throw new Error('Algo no está bien, el formulario solo debe procesarse una única vez.');
      }
    } else {
      currentConfig = this.getFieldConfigFromSelector(formElement.ClientPath);
      currentConfig.children = {};
      currentConfig.FormElement = formElement;
      this.formStateApiManager.registerElementInStateAndActionApi(formElement);
    }

    if (isNullOrUndefined(formGroup)) {
      formGroup = this.fb.group({});
      if (this.calculateDisabledFromConfigHierarchy(currentConfig)) {
        formGroup.disable();
      }
    }

    let rowNum: number = 0;
    rows[rowNum] = [];

    // Sort child items.
    const childrenSorted: FormElement[] = Object.keys(formElement.Children).map(function (key: string): FormElement {
      const item: FormElement = formElement.Children[key];
      item['key'] = key;
      return item;
    }).sort(function (a: FormElement, b: FormElement): number {
      return a.Order - b.Order;
    });

    // Es importante cargar los valores ahora para mejorar
    // el rendimiento de la carga inicial y de los rebuilds
    const defaultValues: object = {};
    this.setObjectValuesForComponent(formElement, defaultValues, this.formState.formState);

    for (const childElement of childrenSorted) {

      if (isNullOrUndefined(this.formClientIdToClientPaths[childElement.ClientId])) {
        this.formClientIdToClientPaths[childElement.ClientId] = [];
      }

      this.formClientIdToClientPaths[childElement.ClientId].push(childElement.ClientPath);

      // Setting states for newStateApi
      this.formStateApiManager.registerElementInStateAndActionApi(childElement);

      if (childElement.ComponentNewRow) {
        ++rowNum;
        rows[rowNum] = [];
      }

      const newLineAfterComponent: boolean = childElement.NewRow;

      const conf: FieldConfig = this.getComponentsConfig(childElement, currentConfig);

      // Añadir a config
      currentConfig.children[childElement.ClientId] = conf;

      rows[rowNum].push(conf);

      // Es importante por motivos de rendimiento que en la construcción del componente ya establezcamos el valor,
      // así evitamos el redibujado una vez se pone el valor..
      let defaultValue: any = null;

      // Como siempre el form tiene un tratamiento especial porque es de primer nivel..
      if (formElement.Type === FormElementType.Form) {
        defaultValue = getInSafe(defaultValues, (i) => i[childElement.ClientId]);
      } else if (!this.formElementIsContainer(childElement.Type)) {
        defaultValue = getInSafe(defaultValues, (i) => i[formElement.ClientId][childElement.ClientId])
      }

      // El valor que le meteremos al component es el Value, y si no hay, el default value
      // this.setObjectValuesForComponent(childElement, objectToSet, this.getFormState());
      this.createComponent(formGroup, conf, defaultValue);

      if (newLineAfterComponent) {
        ++rowNum;
        rows[rowNum] = [];
      }
    }

    return formGroup;
  }

  /**
   * Poner el foco en el primer input disponible que lo permita
   */
  public focusFirstAvailableFormElement(): void {
    const element: FormElement = this.getConfigFromSelector('');
    this.doFocusFirstAvailableFormElement(element);
  }

  /**
   * Obtener a partir de la jerarquía de controles si estamos o no deshabilitados
   * @param config
   */
  public calculateDisabledFromConfigHierarchy(config: FieldConfig): boolean {
    let configDisabled: boolean = false;
    let elementConfig: FieldConfig = config;
    while (elementConfig) {
      // Si alguno de mis padres no es editable, o no está visible, yo no soy editable
      if (elementConfig.editable === false || elementConfig.visible === false) {
        configDisabled = true;
        break;
      }
      elementConfig = elementConfig.ParentConfig;
    }
    return configDisabled;
  }

  /**
   * Pone el foco en el primer elemento de formulario disponible que puede tener foco
   */
  protected doFocusFirstAvailableFormElement(element: FormElement): boolean {
    for (const e of UtilsTypescript.ObjectValues(element.Children)) {
      if (this.formElementIsContainer(e.Type)) {
        if (this.doFocusFirstAvailableFormElement(e)) {
          return true;
        }
        continue;
      } else {
        // Check if we have an available input, not all form inputs have one!
        const formElementInstance: FrontendFormElementInput = this.getFormComponentInputInstanceSafe(e.ClientPath);
        // Si devuelve true es que hemos podido poner el focus
        if (formElementInstance) {
          const abstractControl: AbstractControl = this.getFormComponent(e.ClientPath);
          const fieldConfig: FieldConfig = this.getFieldConfigFromSelector(e.ClientPath);
          // Solo ponemos el foco si está visible y habilitado
          if (abstractControl.enabled && fieldConfig.visible && formElementInstance.focusInput()) {
            return true;
          }
        }
      }
    }
  }

  /**
   * Prepara un objecto inciailizado con el valor que debe tener un control, que es el DefaultValue que viene
   * de backend, o el contenido actual que hay en FormState.Values
   *
   * @param formElement
   * @param objectToSet
   * @param formState
   */
  setObjectValuesForComponent(formElement: FormElement, objectToSet: object, formState: FormState): void {
    if (this.formElementIsContainer(formElement.Type)) {
      // Except for the top level container, initialize a container object
      if (formElement.Type !== FormElementType.Form) {
        objectToSet = objectToSet[formElement.ClientId] = {};
      }
      for (const child of Object.keys(formElement.Children)) {
        this.setObjectValuesForComponent(formElement.Children[child], objectToSet, formState);
      }
      return;
    }

    const formElementInput: FormElementInput = Object.assign(new FormElementInput(), formElement);
    let value: any = this.getFormElementValueFromFormState(formState.Values, formElementInput, formState, formElementInput.DefaultValue);

    // Si la entrada es del tipo object, clonamos el valor para que no esté acoplado
    if (value && typeof (value) === 'object') {
      value = UtilsTypescript.jsonClone(value);
    }

    // Y si es un array, lo "copiamos" también para evitar que manipulaciones hechas por el control afecten al valor original
    if (value && Array.isArray(value)) {
      value = [...value];
    }

    objectToSet[formElement.ClientId] = UtilsTypescript.getNewtonSoftRealValue(value);
  }

  /**
   * Obtiene del form-state el valor de un campo
   *
   * @param formStateValues
   * @param formElement
   */
  getFormElementValueFromFormState(formStateValues: object, formElement: FormElement, formState: FormState, defaultValue: any): any {
    if (formState.Form.EnableElementValueNesting === true) {
      const selectorParts: string[] = formElement.ClientPath.split('.');
      let currentPart: any = formStateValues;
      for (let i: number = 0; i < selectorParts.length; i++) {
        if (currentPart && currentPart.hasOwnProperty(selectorParts[i])) {
          currentPart = currentPart[selectorParts[i]];
          if (i === selectorParts.length - 1) {
            return currentPart;
          }
        } else {
          break;
        }
      }
    } else {
      if (formStateValues && formStateValues.hasOwnProperty(formElement.ClientId)) {
        return formStateValues[formElement.ClientId];
      }
    }
    return defaultValue;
  }

  /**
   * Patches the current values and default values of the form state into the form
   */
  patchValuesAndDefaultValuesFromFormState(formState: FormState, emitEvent: boolean = true): void {
    const objectToPatch: object = {};
    this.setObjectValuesForComponent(formState.Form, objectToPatch, formState);
    this.patchValuesToAngularFormRecursive(objectToPatch, this.form, emitEvent);
  }

  /**
   * El patch values de angular napp-company-employeeso es recursivo, unicamente se puede usar como mucho
   * a nivel de from group para afectar a los hijos directos.
   *
   * @param value
   */
  patchValuesToAngularFormRecursive(value: any, formGroup: FormGroup, emitEvent: boolean): void {
    Object.keys(value).forEach(function (name: string): void {
      const control: AbstractControl = formGroup.controls[name];
      if (!control) {
        return;
      }
      if (control instanceof FormGroup) {
        this.patchValuesToAngularFormRecursive(value[name], control, emitEvent);
        return;
      }
      control.patchValue(value[name], {onlySelf: false, emitEvent: emitEvent});
    }.bind(this));
  }

  /**
   * Creates a new component on fieldset view container
   * @param group
   * @param config
   * @param parentName
   */
  createComponent(group: FormGroup, config: FieldConfig, value: any): FormControl {
    // check if component is a secret component
    if (config.type === FormElementType.Secret) {
      return;
    }
    // create logical component
    const control: FormControl = this.createControl(this.fb, config, value);
    group.addControl(config.name, control);
    return control;
  }

  /**
   * Get a form element's configuration (FormElement)
   *
   * @param selector
   */
  getConfigStackFromSelector(selector: string): Array<FormElement> {
    const result: FormElement[] = new Array<FormElement>();
    this.getConfigStackFromSelectorInternal(selector, this.formState.formState.Form, result);
    return result;
  }

  /**
   * Get a form element's configuration (FormElement)
   *
   * @param selector
   */
  getConfigFromSelector(selector: string): FormElement {
    return this.getConfigFromSelectorInternal(selector, this.formState.formState.Form);
  }

  /**
   * Get a form's element field configuration (the one bound to the object itself)
   *
   * @param selector
   */
  getFieldConfigFromSelector(selector: string): FieldConfig {
    selector = this.normalizeSelector(selector);
    return this.getFieldConfigFromSelectorInternal(selector, this.config);
  }

  /**
   * Find the instance of a form component using the selector
   *
   * @param selector
   */
  getFormComponent(selector: string): AbstractControl {
    selector = this.normalizeSelector(selector);
    return this.getFormComponentFromSelectorInternal(selector.split('.'), this.form);
  }

  /***
   *
   * @param selector
   */
  private normalizeSelector(selector: string): string {
    // Si el selector está vacío, se interpreta que queremos obtener el formulario completo
    if (UtilsTypescript.isNullOrWhitespace(selector)) {
      return '';
    }

    // Si estamos en el modo que permite repeteir id's anidados en fieldsets, no validamos la repetición de elementos, y asumimos
    // que lo que nos pasan es el path
    if ((this.config.FormElement as FormConfiguration).EnableElementValueNesting === true) {
      return selector;
    }

    // Intentamos pillar primero el control usando un selector plano...
    if (!isNullOrUndefined(this.formClientIdToClientPaths[selector])) {
      if (this.formClientIdToClientPaths[selector].length > 1) {
        throw new Error('No se puede identificar de manera única el selector: ' + selector);
      } else {
        return this.formClientIdToClientPaths[selector][0];
      }
    }
    return selector;
  }

  /**
   * Return a a form element given it's path selector
   *
   * @param {string} selector
   * @param config
   * @returns {any | any | any}
   */
  private getConfigFromSelectorInternal(selector: string, formElement: FormElement): FormElement {
    const selectorParts: string[] = selector.split('.');
    if (selectorParts.length === 1) {
      if (UtilsTypescript.isNullOrWhitespace(selector)) {
        return formElement;
      } else {
        return formElement.Children[selector];
      }
    } else {
      const configToLookIn: FormElement = formElement.Children[selectorParts[0]];
      const remainingPath: string = selectorParts.slice(1, selectorParts.length).join('.');
      return this.getConfigFromSelectorInternal(remainingPath, configToLookIn);
    }
  }

  /**
   * Return a a form element given it's path selector
   *
   * @param {string} selector
   * @param config
   * @returns {any | any | any}
   */
  private getConfigStackFromSelectorInternal(selector: string, formElement: FormElement, output: Array<FormElement>): void {
    const selectorParts: string[] = selector.split('.');
    if (selectorParts.length === 1) {
      if (UtilsTypescript.isNullOrWhitespace(selector)) {
        output.push(formElement);
        return;
      } else {
        output.push(formElement.Children[selector]);
        return;
      }
    } else {
      const configToLookIn: FormElement = formElement.Children[selectorParts[0]];
      const remainingPath: string = selectorParts.slice(1, selectorParts.length).join('.');
      output.push(configToLookIn);
      this.getConfigStackFromSelectorInternal(remainingPath, configToLookIn, output);
    }
  }

  /**
   * @param selector
   * @param fieldConfig
   */
  private getFieldConfigFromSelectorInternal(selector: string, fieldConfig: FieldConfig): FieldConfig {
    const selectorParts: string[] = selector.split('.');
    if (selectorParts.length === 1) {
      if (UtilsTypescript.isNullOrWhitespace(selector)) {
        return fieldConfig;
      } else {
        return fieldConfig.children[selector];
      }
    } else {
      const configToLookIn: FieldConfig = fieldConfig.children[selectorParts[0]];
      if (configToLookIn === undefined) {
        return {};
      }
      const remainingPath: string = selectorParts.slice(1, selectorParts.length).join('.');
      return this.getFieldConfigFromSelectorInternal(remainingPath, configToLookIn);
    }
  }

  /**
   *
   * @param selector
   * @param formGroup
   */
  private getFormComponentFromSelectorInternal(selector: string[], formGroup: FormGroup): AbstractControl {
    if (selector.length === 1) {
      if (UtilsTypescript.isNullOrWhitespace(selector[0])) {
        return formGroup;
      } else {
        return formGroup.controls[selector[0]];
      }
    } else {
      const configToLookIn: AbstractControl = formGroup.controls[selector[0]];
      if (!(configToLookIn instanceof FormGroup)) {
        throw new Error('Invalid selector: ' + selector);
      }
      const remainingPath: string[] = selector.slice(1, selector.length);
      return this.getFormComponentFromSelectorInternal(remainingPath, configToLookIn as FormGroup);
    }
  }

  /**
   * Get a value from an object using a path
   */
  getNestedValue(container: any, selector: string): any {
    const selectorParts: string[] = selector.split('.');
    if (selectorParts.length === 1) {
      if (UtilsTypescript.isNullOrWhitespace(selector)) {
        return container;
      } else {
        return container[selector];
      }
    } else {
      const configToLookIn: FieldConfig = container[selectorParts[0]];
      if (configToLookIn === undefined) {
        return {};
      }
      const remainingPath: string = selectorParts.slice(1, selectorParts.length).join('.');
      return this.getNestedValue(configToLookIn, remainingPath);
    }
  }

  /**
   * Creates a new angular's form control from a field CONFIG
   *
   * @param {FieldConfig} config
   * @returns {FormControl}
   */
  createControl(formBuilder: FormBuilder, config: FieldConfig, value: any): FormControl {
    const disabled: boolean = this.calculateDisabledFromConfigHierarchy(config);
    return formBuilder.control({disabled, value}, null, null);
  }

  /**
   * Get the value of a component. Careful because this value is updated
   * before the valueChanges() event is fired.
   *
   * @param selector
   * @param value
   * @param propagate
   */
  getFormComponentValue(selector: string): any {
    return this.getFormComponent(selector).value;
  }

  /**
   * @deprecated Angular no tiene una manera estable de obtener el valor previo de un componente con un cambio de valor,
   * usar pairWise() en el pipe para llevar un registro de los cambios. Este método se apalancaba en algo circunstancial
   * (que el valor todavía no estaba propagado en todos sitios) para obtener el valor "anterior" de un componente,
   * pero su funcionamiento no es fiable.
   *
   * Get the value of a component. This value is updated after
   * the valueChanges() event is fired.
   * @param selector
   *   Path or control id
   *
   *  CUIDADO: Este método se apalanca el hecho de que el this.form.value se actualiza después
   *  de todo el ciclo de eventos de propagación, pero tiene varios problemas:
   *
   *  [1] Si el componente pasa a estar deshabilitado (y antes estaba habilitado) no obtiene su valor (ni hay manera de hacerlo
   *  porque getRawValues() ya ha sido actualizado internamente durante el ciclo de propagación de cambios)
   */
  getFormComponentValueActive(selector: string): any {
    return this.getNestedValue(this.form.value, this.getFieldConfigFromSelector(selector).ClientPath);
  }

  /**
   * Get a form component's value, including DISABLED elements.
   *
   * @param selector
   */
  getFormComponentValueRaw(selector: string, formRawValue: any = null): any {
    return this.getNestedValue(formRawValue ?? this.form.getRawValue(), this.getFieldConfigFromSelector(selector).ClientPath);
  }

  /**
   * Set the value of a form component
   *
   * @param selector
   * @param value
   * @param propagate
   */
  setFormComponentValue(selector: string, value: any, propagate: boolean = false): void {
    const component: AbstractControl = this.getFormComponent(selector);
    if (backendTypeMatch(FormElementSecret.$type, component)) {
      // Este caso puede ocurrir si intento manipular un componente al que el usuario no tiene acceso (access false)
      // mostramos un mensaje para ayudar al desarrollador, pero esto no es un problema.
      console.warn('Se está intentado establecer el valor de un componente (' + selector + ') al que el usuario no tiene acceso.');
      return;
    }
    component.setValue(value, {emitEvent: propagate});
    this.elementConfigChanged.emit(selector);
  }

  /**
   * Set all form component's values recursively to NULL/EMPTY
   *
   * @param selector
   */
  resetFormComponent(selector: string, propagate: boolean = false): void {
    const component: FormElement = this.getConfigFromSelector(selector);
    if (backendTypeMatch(FormElementSecret.$type, component)) {
      // Este caso puede ocurrir si intento manipular un componente al que el usuario no tiene acceso (access false)
      // mostramos un mensaje para ayudar al desarrollador, pero esto no es un problema.
      console.warn('Se está intentado reiniciar el valor de un componente (' + selector + ') al que el usuario no tiene acceso.');
      return;
    }
    // No vamos a llamar a .reset() de angular porque crearia inconsistencias
    // con el DefaultValue(), debemos poner explicitamente el campo como borrado
    // component.reset();
    if (component.Children && this.formElementIsContainer(component.Type)) {
      for (const key of Object.keys(component.Children)) {
        this.resetFormComponent(component.Children[key].ClientPath, propagate)
      }
      return;
    }
    this.setFormComponentValue(selector, null, propagate);
  }

  /**
   * Path value for a form component.
   *
   * @param selector
   * @param value
   * @param propagate
   */
  patchFormComponentValue(selector: string, value: any, propagate: boolean = false): void {
    this.getFormComponent(selector).patchValue(value, {emitEvent: propagate});
  }


  /**
   * getComponentsConfig: setting all component propieties
   * @param item
   */
  getComponentsConfig(item: FormElement, parentConfig: FieldConfig): FieldConfig {

    const conf: FieldConfig = {};

    // FormElement Attributes
    conf.type = item.Type;
    conf.classes = item.Classes;
    conf.description = item.Description;
    conf.label = item.Title;
    conf.offsetWidth = item.OffsetWidthColumn || 0;
    conf.editable = item.ReadOnly === true ? false : true;
    conf.toolTip = item.Tooltip;
    conf.visible = item.Visible === true ? true : false;
    conf.width = item.WidthColumn || 12;
    conf.newLine = false;
    conf.name = item.ClientId;
    conf.ConfirmChangeMessage = (item as FormElementButton).ConfirmChangeMessage;
    conf.ClientId = item.ClientId;
    conf.ClientPath = item.ClientPath;
    conf.dataAttributes = item.DataAttributes;
    conf.maxlength = (item as FormElementInput).MaxLength;
    conf.characterCounterMode = (item as FormElementInput).CharacterCounterMode;
    conf.showRemainingCharCounter = (item as FormElementInput).ShowRemainingCharCounter;
    // FormElementInput Attributes
    conf.async = (item as FormElementInput).AsyncValidation;
    conf.cssClassesDescription = asIterable((item as FormElementInput).CssClassesDescription);
    conf.cssClassesLabel = asIterable((item as FormElementInput).CssClassesLabel);
    conf.cssClassesTooltip = asIterable((item as FormElementInput).CssClassesTooltip);
    conf.cssClassesValue = asIterable((item as FormElementInput).CssClassesValue);
    conf.defaultValue = (item as FormElementInput).DefaultValue;
    // Normalizamos el default value a nulo, ya que cuando viene nulo de backend
    // el serializador NO lo añade al DTO, lo que en javascript da un Undefined
    // lo que no es correcto
    if (isNullOrUndefined(conf.defaultValue)) {
      conf.defaultValue = null;
    }
    conf.placeholder = (item as FormElementInput).Placeholder || '';
    conf.required = (item as FormElementInput).Required === true ? true : false;
    // FormElementMap Attributes
    conf.addessMappingObject = (item as FormElementMap).AddressMapping;
    conf.editableMap = (item as FormElementMap).EditableMap === true ? true : false;
    conf.latitudeElementKey = (item as FormElementMap).LatitudeElementKey;
    conf.longitudeElementKey = (item as FormElementMap).LongitudeElementKey;
    // FormElementButton
    conf.action = (item as FormElementButton).Action;
    conf.buttonClasses = asIterable((item as FormElementButton).ButtonClasses);
    conf.options = (item as FormElementFixedOptionsBase).Options;
    // FormElementDate
    conf.maxDate = (item as FormElementDate).MaxDate;
    conf.minDate = (item as FormElementDate).MinDate;
    conf.isMultiselect = (item as FormElementAutocomplete).IsMultiselect;
    conf.maxElements = (item as FormElementAutocompleteNew).MaxElements;

    conf.disableAutoSort = (item as FormElementFixedOptionsBase).DisableAutoSort;
    conf.showCheckIfNotEmpty = (item as FormElementRadio).ShowCheckIfNotEmpty;
    conf.rows = (item.hasOwnProperty('Rows')) ? item['Rows'] : null;
    // FormElementFiles
    conf.maxFiles = (item as FormElementFile).MaxFiles;
    conf.defaultComponentMode = (item as FormElementFile).DefaultComponentMode;
    conf.metadata = item.Metadata as any[];
    conf.validSize = (item as FormElementFile).ValidSize;
    conf.validExtensions = asIterable((item as FormElementFile).ValidExtensions);
    conf.autoUpload = (item as FormElementFile).DisableAutomaticUpload === true ? false : true;
    conf.Html = (item as FormElementHtmlPlaceholder).Html;
    conf.Messages = (item as FormElementSimpleMessage).Messages;
    conf.FormElement = item;
    conf.ParentConfig = parentConfig;
    conf.step = (item as FormElementInputNumber).Step;
    conf.minValue = (item as FormElementInputNumber).MinValue;
    conf.maxvalue = (item as FormElementInputNumber).MaxValue;
    conf.ShowSelectAll = (item as FormElementCheckboxesModal).ShowSelectAll;
    conf.Size = (item as FormElementQRCode).Size;
    conf.TimeZone = (item as FormElementDatePicker).TimeZone;
    conf.TimeZone = (item as FormElementDateTimePicker).TimeZone;

    conf.MaxWidth = (item as FormElementAvatar).MaxWidth;
    conf.MinWidth = (item as FormElementAvatar).MinWidth;

    conf.MaxHeight = (item as FormElementAvatar).MaxHeight;
    conf.MinHeight = (item as FormElementAvatar).MinHeight;

    conf.Autoplay = (item as FormElementVideo).Autoplay;
    conf.Controls = (item as FormElementVideo).Controls;
    conf.Height = (item as FormElementVideo).Height;
    conf.Width = (item as FormElementVideo).Width;
    conf.Loop = (item as FormElementVideo).Loop;
    conf.Muted = (item as FormElementVideo).Muted;
    conf.VideoType = (item as FormElementVideo).VideoType;
    conf.VideoId = (item as FormElementVideo).VideoId;

    this.setComponentConfig(item, conf);
    return conf;
  }

  /**
   * Set element properties by destructuring.
   *
   * @param item FormElement to destructure from.
   * @param config Config to add properties.
   */
  private setComponentConfig(item: FormElement, config: FieldConfig): FieldConfig {
    const type: string = getInSafe(item, i => i.$type, null);
    let source: Object;

    switch (type) {
      case FormElementViewsEmbed.name:
        const {ViewsArguments, ViewsId, IsMultiselect} = (item as FormElementViewsEmbed);
        source = {ViewsArguments, ViewsId, IsMultiselect}
        break;
      case FormElementFromContentsProgress.name:
        const {
          ProgressElements,
          ProgressStepValue,
          ProgressUpdateOnChange,
          ProgressUseSteps
        } = (item as FormElementFromContentsProgress);
        source = {
          ProgressElements,
          ProgressStepValue,
          ProgressUpdateOnChange,
          ProgressUseSteps
        }
        break;
    }

    return Object.assign(config, source);
  }
}
